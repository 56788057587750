<app-shared-layout>
  <ng-container *ngIf="hasAccess">
    <div class="header">
      <app-header-landingpage></app-header-landingpage>
    </div>
    <div class="logoutContainer showPanel" (click)="toggleContainer()">
      <div class="Logout">
        <div class="log-out-icon icon"></div>
      </div>
    </div>
    <div class="polygon showPanel" *ngIf="showContainer">
      <div class="polygon-icon poly-icon"></div>
    </div>
    <div class="setting-container showPanel" *ngIf="showContainer">
      <div class="setting-blocks" (click)="OnUserManagementClick()" *ngIf="isUserManagementShown">
        <div class="setting-block-icon user-management-icon"></div>
        <div class="setting-block-name">User & Project Management</div>
      </div>
      <!-- *ngIf="isUserManagementShown" -->
      <!--*ngIf="isAddClientStudyShown"-->
      <!--*ngIf="isUserManagementShown"-->
      <div class="setting-blocks" (click)="addClientStudy()" *ngIf="isAddClientStudyShown">
        <div class="setting-block-icon add-icon"></div>
        <div class="setting-block-name">Add/Change Client Details</div>
      </div>
      <div class="setting-blocks" (click)="OnDataUploadClick()" *ngIf="isDataUploadShown">
        <div class="setting-block-icon data-upload-icon"></div>
        <div class="setting-block-name">Data Upload</div>
      </div>
      <!--<div class="setting-blocks">
    <div class="setting-block-icon upload-icon"></div>
    <div class="setting-block-name">Data Upload Module</div>
  </div>-->
      <div class="setting-blocks" (click)="LogOut()">
        <div class="setting-block-icon logout-icon"></div>
        <div class="setting-block-name">Log out</div>
      </div>
    </div>

    <div class="container">
      <!--<div class="containerList" (click)="pvpDemoClick()">
        <div class="Tab">
          <div class="Tab2Image"></div>
        </div>
        <div class="TabText">
          Snapshot
        </div>
        <div class="TabPlay">
          <div class="Arrow"></div>
        </div>
      </div>-->
      <div class="containerList" *ngIf="accessOnLoad || CrosstabAccess" (click)="handleClick()">
        <div class="Tab">
          <div class="Tab1Image"></div>
        </div>
        <div class="TabText">Data Explorer - CrossTab</div>
        <div class="TabPlay">
          <div class="Arrow"></div>
        </div>
      </div>

      <div class="containerList" *ngIf="accessOnLoad || SnapshotAccess" (click)="handleSnapShotClick()">
        <div class="Tab">
          <div class="Tab2Image"></div>
        </div>
        <div class="TabText">Snapshot</div>
        <div class="TabPlay">
          <div class="Arrow"></div>
        </div>
      </div>

    </div>
    <div class="popup-container" *ngIf="showIframe">
      <div class="popup-content">
        <div class="close-icon" (click)="closePopup()"></div>
        <iframe title="Landing Page"
                width="1140"
                height="1058"
                [src]="powerBIurl"
                frameborder="0"
                allowFullScreen="true"></iframe>
      </div>
    </div>
    <!-- <div class="showIframe" *ngIf="showIframe"> -->
    <!-- </div> -->
  </ng-container>
  <div class="noaccess-container" *ngIf="!hasAccess && this.isLoaded">
    You do not have access; please contact the administrator.
  </div>
</app-shared-layout>

<app-shared-popup *ngIf="showPopUp"
                  (closePopUp)="onCloseIconClick($event)"></app-shared-popup>

<app-shared-popup *ngIf="showSnapPopup" [showLoaderSnapShotPopup]="showSnapPopup"
                  (showLoaderSnapShotPopup)="showSnapPopup"
                  (closePopUp)="onCloseIconClickSnapShot($event)"></app-shared-popup>
