<link rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
<app-header-nav-bar>
  <!-- <app-header-brandlogo style="visibility: hidden"></app-header-brandlogo> -->
  
  <app-header-upload-module [active_line1]="ActiveModule1" [active_line2]="ActiveModule2"
    [active_Menu]="'user'"></app-header-upload-module>
    <div style="width: 74%;"></div>
  <app-header-settings [settingIconShown]="true" [userManagementShown]="false" [excelExport]="excelExport"
    [helpGuide]="helpGuide" [pptExport]="pptExport" [repository]="repository"
    [exportDownloads]="exportDownloads"></app-header-settings>
</app-header-nav-bar>
<section>
  <div class="client_contaier">
    <div class="client_dropdown">
      <select name="client" class="drop-down-input" (change)="onClientChange($event,'client')" [(ngModel)]="dropdownSelection.client_name">
        <option value="" class="option-style">Select Client</option>
        <option *ngFor="let client of UserClientStudyData" [value]="client.ClientName">{{client.ClientName}}</option>
      </select>
    </div>
    <div class="client_dropdown" [ngClass]="{ 'disabled': !isclientSelected}">
      <select name="study" class="drop-down-input" (change)="onClientChange($event,'study')" [(ngModel)]="dropdownSelection.study">
        <option value="" class="option-style">Select Study</option>
        <option *ngFor="let study of selectedClientStudies" [value]="study">{{study}}</option>
      </select>
    </div>
    <div class="client_dropdown" [ngClass]="{ 'disabled': !isclientSelected || !isStudySelected}">
      <select name="column"  class="drop-down-input" (change)="onClientChange($event,'file')" [(ngModel)]="dropdownSelection.fileType">
      <option value="" class="option-style">Select FileType</option>
      <option *ngFor="let fileType of Filetypes" [value]="fileType.filetype">{{fileType.filetype}}</option>
    </select>
  </div>
  <div class="client_dropdown" [ngClass]="{ 'disabled': !Is_Submit}">
    <div class="submit_div" (click)="OnSubmitClick()">
      <diV class="setting-img"></diV>
      Submit
    </div>
  </div>
<div class="client_dropdown" style="width: 14%; cursor: pointer;" [ngClass]="{ 'disabled': !Is_SubmitClick}">
  <div class="selected-container" [ngClass]="{ 'active-border' : true}" (click)="toggleDropDown()">
    <div class="selected-text text-ellipsis">Select Index</div>
      <div class="dropdown-icon down-arrow-icon" [ngClass]="{ 'up-arrow-icon': isToggleDropdown}"></div>
  </div>
  <div class="dropdown-container" *ngIf="isToggleDropdown">
    <div class="dropdown-options" *ngFor="let item of DeleteIndexTypes" (click)="OnRadioToggle(item)">
      <div class="checkbox-icon checkbox-inactive" [ngClass]="{ 'checkbox-active': item.is_selected }"></div>
      <div class="option-text text-ellipsis" title="{{ item.IndexName }}">
        {{ item.IndexName }}
      </div>
    </div>
  </div>
</div>
<div class="client_dropdown" style="width: 10%;"  [ngClass]="{ 'disabled': !Is_SubmitClick}">
  <div class="submit_div" (click)="OnDeleteClick()" style="width: 60%;">
    Delete
  </div>
</div>
  </div>
  <div class="uopload_container">
    <div class="upload_parent">
      <div class="upload_header">
        <span>
          Upload
        </span>
        <div class="status_div">
          <div class="in_progress">
            <span class="material-symbols-outlined">
              schedule
            </span>
            In Progress

          </div>
          <div class="failed">
            <span class="material-symbols-outlined">
              cancel
            </span>
            Failed

          </div>
          <div class="success">
            <span class="material-symbols-outlined">
              check_circle
            </span>
            Success

          </div>
        </div>
      </div>
      <div class="upload_body_grid_container" [ngClass]="{ 'disabled': !Is_Submit}">
        <div class="grid_div">
          <div class="grid_header" [ngStyle]="{'background-color': getStatusColor('sanity')}">
            <span>Data Sanity</span>
          </div>
            <div class="upload_div" [ngClass]="{ 'statusActive': getSectionActive('section1')}" [ngStyle]="{'border-color': getStatusColor('section1')}"  #dataSanityRawDataConfig>
            <div class="upload_div_span">
              <span [ngStyle]="{'color': getStatusColor('section1')}">Place the raw data file and config File:</span>
              <div class="upload_text" [ngClass]="{ 'disabled': getUploadDisableStatus('section1')}">
                <span class="upload_span">{{BindFileName.sanityInput}}</span>
                <!-- <span class="material-symbols-outlined" [ngClass]="{ 'disabled': this.isTriggered['section1'],'pointer':this.StatusUpdate.section1}" (click)="ChooseFile.click()"> -->
                <span class="material-symbols-outlined" [ngClass]="{ 'disabled': this.isTriggered['section1']}" (click)="ChooseFile.click()">
                  upload
                  <input #ChooseFile type="file" accept=".zip" onclick="value = null"  (change)="onFileInput($event,'section1')" style="display:none;" />
                </span>
              </div>
            </div>
            <div class="play" (click)="onTrigger('section1','RawData')" [ngClass]="{ 'disabled': isFilenotAvailable || isButtonDisableWhenClickOnTrigger}">
              <span class="material-symbols-outlined" [ngStyle]="{'color': getStatusColor('section1')}">
                {{ this.defaultPlayButton['section1'] ? 'play_circle' : getStatusIcon('section1') }}
              </span>
            </div>
          </div>
          <div class="upload_div" [ngClass]="{ 'statusActive': getSectionActive('section2')}" [ngStyle]="{'border-color': getStatusColor('section2')}" #dataSanityInclusiveFile>

            <div class="upload_div_span">
              <span [ngStyle]="{'color': getStatusColor('section2')}">Inclusion List Update:</span>
              <div class="upload_text" [ngClass]="{ 'disabled': getUploadDisableStatus('section2')}">
                <div class="upload_span">{{BindFileName.inclusionInput}}</div>
                <div class="multiple_upload">
                  <span class="material-symbols-outlined" (click)="OnDownloadFile('section2','inclusion')">
                    download
                  </span>
                  <!-- <span class="material-symbols-outlined" [ngClass]="{ 'disabled': this.isTriggered['section2']}" [ngStyle]="{ 'cursor': this.StatusUpdate.section2 ? 'pointer' : 'auto' }" (click)="inclusionFile.click()"> -->
                    <span class="material-symbols-outlined" [ngClass]="{ 'disabled': this.isTriggered['section2']}" (click)="inclusionFile.click()">
                  upload
                  <input #inclusionFile type="file" onclick="value = null" (change)="onFileInput($event,'section2')" style="display:none;" />
                </span>
                 
                </div>
              </div>
            </div>
            <div class="play" (click)="onTrigger('section2','inclusion')" [ngClass]="{ 'disabled': isFilenotAvailable || isButtonDisableWhenClickOnTrigger}">
              <span class="material-symbols-outlined" [ngStyle]="{'color': getStatusColor('section2')}">
                {{ this.defaultPlayButton['section2'] ? 'play_circle' : getStatusIcon('section2') }}
              </span>
            </div>
          </div>
          <div class="upload_div" [ngClass]="{ 'statusActive': getSectionActive('section3')}" [ngStyle]="{'border-color': getStatusColor('section3')}"  #dataSanityVariableMapping>
            <div class="upload_div_span">
              <span [ngStyle]="{'color': getStatusColor('section3')}">Variable Mapping verification:</span>
              <div class="upload_text" [ngClass]="{ 'disabled': getUploadDisableStatus('section3')}">
                <div class="upload_span">{{BindFileName.mappingInput}}</div>
                <div class="multiple_upload">
                  <!-- <span class="material-symbols-outlined" [ngStyle]="{ 'cursor': this.StatusUpdate.section3 ? 'pointer' : 'auto' }" (click)="OnDownloadFile('section3','variable-mapping')"> -->
                    <span class="material-symbols-outlined" (click)="OnDownloadFile('section3','variable-mapping')">
                    download
                  </span>
                  <!-- <span class="material-symbols-outlined" [ngClass]="{ 'disabled': this.isTriggered['section3']}" [ngStyle]="{ 'cursor': this.StatusUpdate.section3 ? 'pointer' : 'auto' }" (click)=" MappingFile.click()"> -->
                  <span class="material-symbols-outlined" [ngClass]="{ 'disabled': this.isTriggered['section3']}" (click)=" MappingFile.click()">
                  upload 
                  <input #MappingFile type="file" onclick="value = null" (change)="onFileInput($event,'section3')" style="display:none;" />
                </span>
                 
                </div>
              </div>
            </div>
            <div class="play" (click)="onTrigger('section3','variable-mapping')" [ngClass]="{ 'disabled': isFilenotAvailable || isButtonDisableWhenClickOnTrigger}">
              <span class="material-symbols-outlined" [ngStyle]="{'color': getStatusColor('section3')}">
                {{ this.defaultPlayButton['section3'] ? 'play_circle' : getStatusIcon('section3') }}
              </span>
            </div>
          </div>
        </div>
        <div class="grid_div">
          <div class="grid_header"  [ngStyle]="{'background-color': getStatusColor('IS')}">
            <span>Intermediate Server Data Load</span>
          </div>
          <div class="upload_div" [ngClass]="{ 'statusActive': getSectionActive('section4')}" [ngStyle]="{'border-color': getStatusColor('section4')}" #intermediateTimePeriod>
            <div class="upload_div_span">
              <span [ngStyle]="{'color': getStatusColor('section4')}">Time Period Update:</span>
              <div class="upload_text" [ngClass]="{ 'disabled': getUploadDisableStatus('section4')}">
                <div class="upload_span">{{BindFileName.timeperiodInput}}</div>
                <div class="multiple_upload">
                  <!-- <span class="material-symbols-outlined" [ngStyle]="{ 'cursor': this.StatusUpdate.section4 ? 'pointer' : 'auto' }" (click)="OnDownloadFile('section4','timeperiod')"> -->
                  <span class="material-symbols-outlined" (click)="OnDownloadFile('section4','timeperiod')">
                    download
                  </span>
                  <!-- <span class="material-symbols-outlined" [ngClass]="{ 'disabled': this.isTriggered['section4']}" [ngStyle]="{ 'cursor': this.StatusUpdate.section4 ? 'pointer' : 'auto' }" (click)="tPInputsFile.click()"> -->
                <span class="material-symbols-outlined" [ngClass]="{ 'disabled': this.isTriggered['section4']}" (click)="tPInputsFile.click()">
                  upload
                  <input #tPInputsFile type="file" onclick="value = null" (change)="onFileInput($event,'section4')" style="display:none;" />
                </span>
                  
                </div>
              </div>
            </div>
            <div class="play" (click)="onTrigger('section4','timeperiodInput')" [ngClass]="{ 'disabled': timePeriodPlayButtonEnable}">
              <span class="material-symbols-outlined"[ngStyle]="{'color': getStatusColor('section4')}">
                {{ this.defaultPlayButton['section4'] ? 'play_circle' : getStatusIcon('section4') }}
                
              </span>
            </div>
          </div>
          <div class="upload_div" style="height: 57%;" [ngClass]="{ 'statusActive': getSectionActive('section5')}" [ngStyle]="{'border-color': getStatusColor('section5')}" #intermediateSelectionPanel>
            <div class="multiple_fom_input">
              <span [ngStyle]="{'color': getStatusColor('section5')}">Selection-panel View Update:</span>
              <div class="fom_input" [ngClass]="{ 'disabled': getUploadDisableStatus('section6')}">
                <!-- <div class="multiple_upload"> -->
                  <div class="upload_div_span">
                    <div class="upload_text" [ngClass]="{ 'disabled': getUploadDisableStatus('section6')}">
                      <div class="upload_span" >FOM input {{BindFileName.fomInput}}</div>
                      <div class="multiple_upload" >
                        <!-- <span class="material-symbols-outlined" [ngStyle]="{ 'cursor': this.StatusUpdate.section6 ? 'pointer' : 'auto' }" (click)="OnDownloadFile('section6','fom_input')"> -->
                          <span class="material-symbols-outlined" (click)="OnDownloadFile('section6','fom_input')">
                          download
                        </span>
                        <!-- <span class="material-symbols-outlined" [ngClass]="{ 'disabled': this.isTriggered['section6']}" [ngStyle]="{ 'cursor': this.StatusUpdate.section6 ? 'pointer' : 'auto' }" (click)="fOMInput.click()"> -->
                          <span class="material-symbols-outlined" [ngClass]="{ 'disabled': this.isTriggered['section6'] }" (click)="fOMInput.click()">
                          upload
                          <input #fOMInput type="file" onclick="value = null" (change)="onFileInput($event,'section5')" style="display:none;" />
                        </span>
                      </div>
                    </div>
                  </div>
                <!-- </div> -->

                <div class="play" (click)="onTrigger('section6','fom_input')" [ngClass]="{ 'disabled': fomInputPlayButtonEnable }">
                  <span class="material-symbols-outlined" [ngStyle]="{'color': getStatusColor('section6')}">
                    {{ this.defaultPlayButton['section6'] ? 'play_circle' : getStatusIcon('section6') }}
                  </span>
                </div>
              </div>
              <div class="fom_input"  [ngClass]="{ 'disabled': !getSectionActive('section7')}">
                <!-- <div class="multiple_upload"> -->
                  <div class="upload_div_span">
                    <div class="upload_text" [ngClass]="{ 'disabled': getUploadDisableStatus('section7')}">
                      <div class="upload_span">FOM File {{BindFileName.fomFile}}</div>
                      <div class="multiple_upload">
                        <!-- <span class="material-symbols-outlined" [ngStyle]="{ 'cursor': this.StatusUpdate.section7 ? 'pointer' : 'auto' }" (click)="OnDownloadFile('section7','fom_file')"> -->
                          <span class="material-symbols-outlined" (click)="OnDownloadFile('section7','fom_file')">
                          download
                        </span>
                        <!-- <span class="material-symbols-outlined"  [ngClass]="{ 'disabled': this.isTriggered['section7']}" [ngStyle]="{ 'cursor': this.StatusUpdate.section7 ? 'pointer' : 'auto' }" (click)="fOMFile.click()"> -->
                          <span class="material-symbols-outlined"  [ngClass]="{ 'disabled': this.isTriggered['section7']}" (click)="fOMFile.click()">
                          upload
                          <input #fOMFile type="file" onclick="value = null" (input)="onFileInput($event,'section7')" style="display:none;" />
                        </span>
                      </div>
                    </div>
                  </div>
                <!-- </div> -->
                <div class="play" (click)="onTrigger('section7','fom_file')" [ngClass]="{ 'disabled': fomFilePlayButtonEnable }">
                  <span class="material-symbols-outlined" [ngStyle]="{'color': getStatusColor('section7')}">
                    {{ this.defaultPlayButton['section7'] ? 'play_circle' : getStatusIcon('section7') }}
                  </span>
                </div>
              </div>
              <div class="fom_input" [ngClass]="{ 'disabled': !getSectionActive('section7')}">
                <!-- <div class="multiple_upload"> -->
                  <div class="upload_div_span">
                    <div class="upload_text">
                      <div class="upload_span">Duplicates</div>
                      <div class="multiple_upload">
                        <span class="material-symbols-outlined" (click)="OnDownloadFile('section8','fom_duplicates')">
                          download
                        </span>
                      </div>
                    </div>
                  </div>
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="grid_div">
          <div class="grid_header" [ngStyle]="{'background-color': getStatusColor('finalDataLoad')}">
            <span>Final Data Load</span>
          </div>
          <div class="upload_div" [ngClass]="{ 'statusActive': getSectionActive('section9') }" [ngStyle]="{'border-color': getStatusColor('section9')}" #finalDataConfigFile>
            <div class="upload_div_span">
              <span>Place the config File:</span>
              <div class="upload_text" [ngClass]="{ 'disabled':getUploadDisableStatus('section9') }">
                <div class="upload_span">{{BindFileName.finalLoadInput}}</div>
                <div class="multiple_upload">
                  <!-- <span class="material-symbols-outlined" [ngClass]="{ 'disabled': this.isTriggered['section9']}" [ngStyle]="{ 'cursor': this.StatusUpdate.section9 ? 'pointer' : 'auto' }" (click)=" finalDataChooseFile.click()"> -->
                    <span class="material-symbols-outlined" [ngClass]="{ 'disabled': this.isTriggered['section9']}" (click)=" finalDataChooseFile.click()">
                  upload
                  <input #finalDataChooseFile type="file" onclick="value = null" (change)="onFileInput($event,'section9')" style="display:none;" />
                </span>
                  <!-- <span class="material-symbols-outlined">
                    download
                  </span> -->
                </div>
              </div>
            </div>
            <div class="play" (click)="onTrigger('section9','finaldata')" [ngClass]="{ 'disabled': isfinalLoadTrigger }">
              <span class="material-symbols-outlined" [ngStyle]="{'color': getStatusColor('section9')}">
                {{ this.defaultPlayButton['section9'] ? 'play_circle' : getStatusIcon('section9') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid_parent">
      <div class="grid_header">
        <span>
          Status Grid
        </span>
        <div class="client_dropdown" [ngClass]="{ 'disabled': !Is_SubmitClick}">
          <div class="submit_div" (click)="DownloadUsageLogReport()">
            <span class="material-symbols-outlined">
              Usage Log
            </span>
          </div>
        </div>
      </div>
      <div class="scrollbar-container" style="margin-top: 5px;height:91%;overflow:scroll;">
        <div class="grid_table_container">
          <table>
            <thead>
              <tr>
                <th class="file-name" rowspan="3">File Name</th>
                <th class="regular-data-update" rowspan="3">Regular Data Update</th>
                <th colspan="12">Data Sanity</th>
                <th colspan="9">Intermediate Server Block</th>
                <th colspan="3" rowspan="2">Final Data Load Block</th>
              </tr>
              <tr>
                <th colspan="3">Creation of Consumable files</th>
                <!-- (2nd section) -->
                <th colspan="3">Inclusion Exclusion File</th>
                <!-- (3rd section) -->
                <th colspan="3">Variable Mapping</th>
                <th colspan="3">Case Data Load to Intermediate Server</th>
                <th colspan="3">Time Period Mapping</th>
                <th colspan="3">Selection Panel File Creation & Update</th>
                <th colspan="3">Selection Panel File Load</th>
              </tr>
              <tr>
                <th class="status">Status</th>
                <th class="initiated">Initiated by</th>
                <th class="time">Time</th>
                <th class="status">Status</th>
                <th class="initiated">Initiated by</th>
                <th class="time">Time</th>
                <th class="status">Status</th>
                <th class="initiated">Initiated by</th>
                <th class="time">Time</th>
                <th class="status">Status</th>
                <th class="initiated">Initiated by</th>
                <th class="time">Time</th>
                <th class="status">Status</th>
                <th class="initiated">Initiated by</th>
                <th class="time">Time</th>
                <th class="status">Status</th>
                <th class="initiated">Initiated by</th>
                <th class="time">Time</th>
                <th class="status">Status</th>
                <th class="initiated">Initiated by</th>
                <th class="time">Time</th>
                <th class="status">Status</th>
                <th class="initiated">Initiated by</th>
                <th class="time">Time</th>
              </tr>
            </thead>
            
            <tbody>
              
              <tr *ngFor="let item of statusdata">
                <td>{{item.FileName}}</td>
                <td>{{item.regulardataupload}}</td>
                <td>{{item.consumable_file_status}}</td>
                <td>{{item.consumable_file_initiated_by}}</td>
                <td>{{item.consumable_file_time}}</td>
                <td>{{item.inclusion_file_status}}</td>
                <td>{{item.inclusion_file_initiated_by}}</td>
                <td>{{item.inclusion_file_time}}</td>
                <td>{{item.variable_mapping_status}}</td>
                <td>{{item.variable_mapping_initiated_by}}</td>
                <td>{{item.variable_mapping_time}}</td>
                <td>{{item.case_data_status}}</td>
                <td>{{item.case_data_initiated_by}}</td>
                <td>{{item.case_data_time}}</td>
                <td>{{item.tp_status}}</td>
                <td>{{item.tp_initiated_by}}</td>
                <td>{{item.tp_time}}</td>
                <td>{{item.fom_input_status}}</td>
                <td>{{item.fom_input_initiated_by}}</td>
                <td>{{item.fom_input_time}}</td>
                <td>{{item.fom_status}}</td>
                <td>{{item.fom_initiated_by}}</td>
                <td>{{item.fom_time}}</td>
                <td>{{item.fd_status}}</td>
                <td>{{item.fd_initiated_by}}</td>
                <td>{{item.fd_time}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
