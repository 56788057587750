import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { LoaderService } from 'src/app/common/services/loader.service';
import { SharedPopupService } from 'src/app/common/services/shared-popup.service';
import { IsTriggered, UploadSubmitObject, DefaultPlayButton } from 'src/app/common/models/upload.model'
import { CrosstabService } from 'src/app/common/services/crosstab.service';
import { Subscription } from 'rxjs';
import { UploadService } from 'src/app/common/services/upload.service';
import { AlertMessageModel } from 'src/app/common/models/common.model';
import { AlertMessageService } from 'src/app/common/services/alert-message.service';
import { sortBy } from 'lodash';
@Component({
  selector: 'app-data-upload',
  templateUrl: './data-upload.component.html',
  styleUrls: ['./data-upload.component.scss']
})
export class DataUploadComponent {

  isfinalLoadTrigger : boolean = false;
  isToggleDropdown: boolean = false;
  ActiveModule1: boolean = false;
  ActiveModule2: boolean = false;
  selectedStudy: string = '';
  isErrorpopupshown: boolean = false;
  selectedClientStudies: string[] = [];
  isclientSelected: boolean = false;
  isStudySelected: boolean = false;
  Filetypes = [{ filetype: 'SPSS', is_selected: false }, { filetype: 'GDF', is_selected: false }, { filetype : 'CRDOM', is_selected : false}];
  Folders = { inclusion: ['inclusion', 'new-variable-mapping'], timeperiod: ['timeperiod'] };
  FileFormat: any;
  UploadObject: any;
  UserClientStudyData: any;
  Is_Submit: boolean = false;
  totalfiles: any;
  file: any;
  IsUploadFileStatus: any = [];
  intervalId : any;
  StatusUpdate = { section1: true, section2: false, section3: false, section4: false, section5: false, section6: false, section7: false, section9: false }
  public SubmitObject: UploadSubmitObject = {
    Client: '',
    study: '',
    filetype: ''
  }
  isTriggered: IsTriggered = { section1: false, section2: false, section3: false, section4: false, section5: false, section6: false, section7: false, section9: false };
  indexCount: number = 0;
  isFilenotAvailable: boolean = true;
  fomInputPlayButtonEnable : boolean = true;
  fomFilePlayButtonEnable : boolean = true;
  timePeriodPlayButtonEnable : boolean = true;
  isButtonDisableWhenClickOnTrigger = true;
  items = [1, 2, 3, 4, 5, 6, 7, 8]
  dropdownSelection = { client_name: '', study: '', fileType: '', JobType: '', FolderName: '' };
  $unSubscribeSubmitData1!: Subscription;
  @Input() userManagementShown: boolean = false;
  @Input() excelExport: boolean = false;
  @Input() helpGuide: boolean = false;
  @Input() pptExport: boolean = false;
  @Input() repository: boolean = false;
  @Input() exportDownloads: boolean = false;
  @ViewChild("dataSanityRawDataConfig") dataSanityRawDataConfig: ElementRef | undefined;
  @ViewChild('ChooseFile') sanityInputFile: ElementRef | undefined;


  //@Input() landingdata: any;
  selectedmoduleCell: number | null = null;
  statusdata: any;
  inputFileName = {
    sanityInput: "Choose File from system", inclusionInput: "Inclusion File (New variable list)", mappingInput: "Mapping File",
    timeperiodInput: "TP inputs file* and Suppress file", fomInput: "", fomFile: "", finalLoadInput: "Choose File from system"
  };
  BindFileName={
      sanityInput: "", inclusionInput: "", mappingInput: "",
      timeperiodInput: "", fomInput: "", fomFile: "", finalLoadInput: ""
  
  }

  defaultPlayButton : DefaultPlayButton = {
    section1 : true,
    section2 : true,
    section3 : true,
    section4 : true,
    section5 : true,
    section6 : true,
    section7 : true,
    section8 : true,
    section9 : true,
  }

  DeleteIndexTypes = [
    { IndexName: "FOM Mapping", originalIndexName : "fom_mapping_data", is_selected : false}, 
    { IndexName : "Time Period Mapping", originalIndexName : "time_period_mapping_data", is_selected: false}, 
    { IndexName : "Grid Status", originalIndexName : "file_upload_status", is_selected : false}];

  Is_SubmitClick : boolean = false;
  constructor(
    public SharedService: SharedPopupService,
    private Loader: LoaderService,
    private crossTabService: CrosstabService,
    private uploadService: UploadService,
    private renderer: Renderer2,
    private _alertService: AlertMessageService
  ) {

  }
  ngOnInit() {

    this.selectedmoduleCell = 1;
    this.BindFileName.sanityInput = this.inputFileName.sanityInput;
    this.BindFileName.inclusionInput = this.inputFileName.inclusionInput;
    this.BindFileName.mappingInput = this.inputFileName.mappingInput;
    this.BindFileName.timeperiodInput = this.inputFileName.timeperiodInput;
    this.BindFileName.fomInput = this.inputFileName.fomInput;
    this.BindFileName.fomFile = this.inputFileName.fomFile;
    this.BindFileName.finalLoadInput = this.inputFileName.finalLoadInput;
  
    this.getUserClientStudy();
    //  if(this.UploadObject.length > 0){
    //    setInterval(() => {
    //     this.starthttprequest();
    //   }, 10000);
    //  }      

  }
  ngAfterViewInit() {
    if (this.Is_Submit) {
      this.renderer.setStyle(this.dataSanityRawDataConfig?.nativeElement, "opacity", "100%",
      );
    }

  }

  toggleDropDown(){
    this.isToggleDropdown = !this.isToggleDropdown;
  }

  getSectionActive(section: any) {
    if (this.statusdata != undefined) {
      if (this.statusdata.length > 0) {     
        switch (section) {
          case "section1":
            if (this.statusdata.length > 0) {
              if (this.statusdata[0].consumable_file_flag) {
                this.StatusUpdate.section1 = true;
                if(this.totalfiles == undefined || this.totalfiles.length == 0){
                  this.BindFileName.sanityInput = this.inputFileName.sanityInput;
                }
                return true;
              }
              else {
                this.StatusUpdate.section1 = false;
                this.renderer.setStyle(this.dataSanityRawDataConfig?.nativeElement, "opacity", "0.5");
                return false;
              }
            }
            else {
              return true;
            }


          case "section2":
            if (this.statusdata[0].inclusion_file_flag) {
              this.StatusUpdate.section2 = true;
              return true;
            }
            else {
              this.StatusUpdate.section2 = false;
              return false;
            }
          case "section3":
            if (this.statusdata[0].variable_mapping_flag) {
              this.StatusUpdate.section3 = true;
              return true;
            }
            else {
              this.StatusUpdate.section3 = false;
              return false;
            }
          case "section4":
            if (this.statusdata[0].tp_flag) {
              this.StatusUpdate.section4 = true;
              return true;
            }
            else {
              this.StatusUpdate.section4 = false;
              return false;
            }
          case "section5":
            if (this.statusdata[0].fom_flag) {
              this.StatusUpdate.section5 = true;
              return true;
            }
            else {
              this.StatusUpdate.section5 = false;
              return false;
            }
          case "section6":
            if (this.statusdata[0].fom_input_flag) {
              this.StatusUpdate.section6 = true;
              return true;
            }
            else {
              this.StatusUpdate.section6 = false;
              return false;
            }
          case "section7":
            if (this.statusdata[0].fom_file_flag) {
              this.StatusUpdate.section7 = true;
              return true;
            }
            else {
              this.StatusUpdate.section7 = false;
              return false;
            }
          case "section9":
            if (this.statusdata[0].fd_flag) {
              this.StatusUpdate.section9 = true;
              return true;
            }
            else {
              this.StatusUpdate.section9 = false;
              return false;
            }
          // Add more cases as needed
          default:
            if (this.statusdata[0].consumable_file_flag) {
              return true;
            }
            else {
              return false;
            }
        }
      }
      else {
        return this.enableSection(section);
      }

    }
    // else{
    //   if(section === "section1"){
    //     this.StatusUpdate.section1= true;
    //     return true;
    //   }
    // }
    return false;

  }
  enableSection(section: any) {
    switch (section) {
      case "section1":
        this.StatusUpdate.section1 = true;
        return true;
      case "section2":

        return false;

      case "section3":

        return false;

      case "section4":

        this.StatusUpdate.section4 = false;
        return false;

      case "section5":

        this.StatusUpdate.section5 = false;
        return false;
      case "section6":

        this.StatusUpdate.section6 = false;
        return false;

      // Add more cases as needed
      default:

        return false;

    }
  }

  DownloadUsageLogReport(){
    this.Loader.showLoader();
    let clientName = this.UploadObject[0].client_name;
    let studyName = this.UploadObject[0].study;
    let fileType = this.UploadObject[0].fileType
    // this.uploadService.ExcelExportForUsageLog(clientName, studyName, fileType).subscribe(
    //     (fileName: any) => {
    //       this.uploadService.downloadExportFile(fileName.Data as string)
    //           .subscribe((exportFile) => {
    //             this.Loader.hideLoader();
    //             const blob = new Blob([exportFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //             const Url = window.URL.createObjectURL(blob);
    //             const anchor = document.createElement('a');
    //             anchor.download = clientName+"_"+studyName+'_Usage Log.xlsx';
    //             anchor.href = Url;
    //             anchor.click();
    //           }, (fileDownloadError:any) => {
    //             this.Loader.hideLoader();
    //             this.errorPopup(fileDownloadError);
    //             this.isErrorpopupshown = true;
    //           });
    //       }, (error) => {
    //         this.Loader.hideLoader();
    //       });
    this.uploadService.ExcelExportForUsageLog(clientName, studyName, fileType)
    .subscribe(
      (excelBlob: Blob) => {
        let fileName = clientName +'_' +studyName +'_Usage Log.xlsx';
        // Trigger file download
        this.downloadFile(excelBlob, fileName);
        this.Loader.hideLoader();
      },
      (error) => {
        console.error('Error exporting to Excel', error);
        this.errorPopup('Something went wrong, please try again');
        this.Loader.hideLoader();
      }
    );
    // this.uploadService.ExcelExportForUsageLog(clientName, studyName, fileType).subscribe((exportFile) => {
    //   this.Loader.hideLoader();
    //   const blob = new Blob([exportFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //   const Url = window.URL.createObjectURL(blob);
    //   const anchor = document.createElement('a');
    //   anchor.download = clientName+"_"+studyName+'_Usage Log.xlsx';
    //   anchor.href = Url;
    //   anchor.click();
    // }, (fileDownloadError:any) => {
    //   this.Loader.hideLoader();
    //   this.errorPopup(fileDownloadError);
    //   this.isErrorpopupshown = true;
    // });    
  }

  private downloadFile(data: Blob, filename: string) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;

    // Trigger download
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  getSectionStatus(section: string, IsIconChange: boolean): any {
    if (this.statusdata != undefined) {
      switch (section) {
        case "section1":
          // this.renderer.setStyle(this.dataSanityRawDataConfig?.nativeElement, "opacity", "100%");
          return this.statusdata.length > 0 ? IsIconChange ? this.IsIconChange(this.statusdata[0].consumable_file_status) : this.statusdata[0].consumable_file_status : this.IsIconChange("not started");

        case "section2":
          return this.statusdata.length > 0 ? IsIconChange ? this.IsIconChange(this.statusdata[0].inclusion_file_status) : this.statusdata[0].inclusion_file_status : this.IsIconChange("not started");
        case "section3":
          return this.statusdata.length > 0 ? IsIconChange ? this.IsIconChange(this.statusdata[0].variable_mapping_status) : this.statusdata[0].variable_mapping_status : this.IsIconChange("not started");
        case "section4":
          return this.statusdata.length > 0 ? IsIconChange ? this.IsIconChange(this.statusdata[0].tp_status) : this.statusdata[0].tp_status : this.IsIconChange("not started");
        case "section5":
          return this.statusdata.length > 0 ? IsIconChange ? this.IsIconChange(this.statusdata[0].fom_block_status) : this.statusdata[0].fom_block_status : this.IsIconChange("not started");
        case "section6":
          return this.statusdata.length > 0 ? IsIconChange ? this.IsIconChange(this.statusdata[0].fom_input_status) : this.statusdata[0].fom_input_status : this.IsIconChange("not started");
        case "section7":
          return this.statusdata.length > 0 ? IsIconChange ? this.IsIconChange(this.statusdata[0].fom_file_status) : this.statusdata[0].fom_file_status : this.IsIconChange("not started");
        case "section9":
          return this.statusdata.length > 0 ? IsIconChange ? this.IsIconChange(this.statusdata[0].fd_status) : this.statusdata[0].fd_status : this.IsIconChange("not started");
        case "sanity":
          return this.statusdata.length > 0 ? this.statusdata[0].data_sanity_status : "not started";
        case "IS":
          return this.statusdata.length > 0 ? 
              (this.statusdata[0].fom_block_status == "completed" && this.statusdata[0].tp_status == "completed" ? 
                "completed" : this.statusdata[0].data_sanity_status != "completed" ? "not started" :
                this.statusdata[0].fom_block_status == "" && this.statusdata[0].tp_status == ""  ? "not started" :  "in-progress")  
                : "not started";
        // Add more cases as needed
        case "finalDataLoad" : 
          return this.statusdata.length > 0 ? this.statusdata[0].fd_status : "not started";
        default:
          return "Start"

      }
    }
    return '';
  }
  getStatusColor(section: string): string {
    // Map status to corresponding CSS class
    let status = this.getSectionStatus(section, false).toLowerCase();
    switch (status) {
      case 'in-progress':
        return '#FF971E';
      case 'failed':
        this.clearFileInput(section);
        return '#DB4F45';
      case 'completed':
        this.clearFileInput(section);
        return '#3BC47C';
      default:
        return '';
    }
  }
  getStatusIcon(section: string): string {
    let status: any;

    if (this.getSectionActive(section)) {
      status = this.getSectionStatus(section, true).toLowerCase();
    }
    else {
      status = this.getSectionStatus(section, false).toLowerCase();
    }
    switch (status) {
      case 'in-progress':
        return 'schedule';
      case 'failed':
        return 'cancel';
      case 'completed':
        return 'check_circle';
      default:
        return 'play_circle';
    }
  }
  IsIconChange(sectionStatus: string) {
    if (sectionStatus == 'in-progress') {
      return sectionStatus;
    }
    else if (sectionStatus == 'failed') {
      return sectionStatus;
    }
    else if(sectionStatus == 'completed'){
      return sectionStatus;
    }
    else {
      return 'start';
    }
  }
  getUploadDisableStatus(section: string) {
    let status = this.getSectionStatus(section, false).toLowerCase();
    
    if (section == "section1" && status == "completed") {
      this.isTriggered[section] = false;
      return false;
    }
    else {
      if (this.statusdata != undefined ) {
        if (!this.statusdata.fom_flag) {
          if (section == "section7" && status == "completed") {
            this.isTriggered[section] = false;
            return false;
          }
          else if (section == "section6" && status == "completed") {
            this.isTriggered[section] = false;
            return true;
          }

        }
      }
    }
    switch (status) {
      case 'in-progress':
        this.isTriggered[section] = true;
        return true;
      case 'failed':
        this.isTriggered[section] = false;
        return false;
      case 'completed':
        this.isTriggered[section] = false;
        return true;
      default:
        //this.isTriggered[section] = false;
        return false;
    }
  }
  getUserClientStudy(): void {
    this.Loader.showLoader();
    this.FileFormat = this.Filetypes;
    this.SharedService.getClientList().subscribe((result) => {
      if (result.HasValidData) {
        this.UserClientStudyData = JSON.parse(result.Data);

      }
      this.Loader.hideLoader();
    });
  }

  OnRadioToggle(query:any){
    if(query){
      query.is_selected = !query.is_selected;
    }
  }

  OnRadioModuleToggle(file: any) {
    this.UploadObject = [];
    // Set the is_selected property of the clicked module to true
    file.is_selected = true;

    // Find any other selected module and set its is_selected property to false
    let otherSelected = this.FileFormat.find((x: any) => x.is_selected && x.filetype !== file.filetype);
    if (otherSelected != null && otherSelected != undefined) {
      otherSelected.is_selected = false;
    }
    // Update the user's moduleName with the selected module's module_name
    this.SubmitObject.Client = 'PMI';
    this.SubmitObject.filetype = file.filetype;
    this.SubmitObject.study = 'NCPT';

    this.UploadObject.push(this.SubmitObject);
    // Trigger the update of the clientStudyName based on the new moduleName

  }

  OnDeleteClick(){
    this.Loader.showLoader();
    let client_study_name = this.UploadObject[0].study;
    this.uploadService.deleteIndex("test-folder-creation", client_study_name, this.DeleteIndexTypes).subscribe(
        (res: any) => {
          console.log(res);
          if (res.HasValidData) {
            this.Loader.hideLoader();
            this.errorPopup(res.Data, "")
            this.isToggleDropdown = false;
            this.DeleteIndexTypes.map(item => {
              item.is_selected = false;
            })
            // Resolve the promise with the response
          } else {
            this.Loader.hideLoader();
            if (!this.isErrorpopupshown) {
              this.errorPopup(res.ExceptionMessage);
              this.isErrorpopupshown = true;
            }
          }
        },
        (error) => {
          this.Loader.hideLoader();
        }
      );
  }

  OnSubmitClick() {
    if( this.intervalId != null &&  this.intervalId != undefined) {
      clearInterval(this.intervalId);
    }
    this.isErrorpopupshown = false;
    this.Is_SubmitClick = true;
    // if(this.UploadObject.length > 0){
    //   this.Is_Submit = true;
    //   this.indexCount = 0;
    //   }
    if (this.dropdownSelection.client_name == "") {

    }
    else if (this.dropdownSelection.study == "") {

    }
    else if (this.dropdownSelection.fileType == "") {

    }
    else {
      // if(this.UploadObject.length > 0){
      //   this.starthttprequest();
      // }
      this.UploadObject = [];
      this.UploadObject.push(this.dropdownSelection);
      if (this.UploadObject.length > 0) {
        this.Loader.showLoader();
        //const selectedsubmitdata = JSON.parse(JSON.stringify(this.UploadObject[0]));
        this.starthttprequest(this.UploadObject[0]);
        //   setInterval(() => {
        //   if(this.UploadObject.length > 0){
        //   const selectedsubmitdata = JSON.parse(JSON.stringify(this.UploadObject[0]));
        //    this.starthttprequest(selectedsubmitdata);
        //   }
        //   else{
        //     this.statusdata = [];
        //   }
        //  }, 10000);



      }
    }

  }
  clearFileInput(section:string): void {
    if (this.sanityInputFile) {
      this.sanityInputFile.nativeElement.value = '';
    }

    // if(section == "section1"){
    //   if(this.totalfiles == undefined){
    //     this.BindFileName.sanityInput = this.inputFileName.sanityInput;
    //   }
    //   else if( this.totalfiles.length == 0 ){
    //     this.BindFileName.sanityInput = this.inputFileName.sanityInput;
    //   }
    // }
    
    
    ;
  }
  // starthttprequest(data:any){
  //   this.$unSubscribeSubmitData1 = this.uploadService.datauploadstatusandgriddata(data).subscribe((res: any) => {
  //     console.log(res);
  //     if (res.HasValidData) {
  //       let jsonData = res;
  //       if(jsonData.Data.length > 0){
  //         this.statusdata = JSON.parse(jsonData.Data);
  //       }        
  //       else{
  //         this.statusdata = [];
  //       }
  //       this.Loader.hideLoader();
  //     }
  //     else {
  //       this.Loader.hideLoader();
  //       if(!this.isErrorpopupshown){
  //         this.errorPopup(res.ExceptionMessage);
  //         this.isErrorpopupshown = true;
  //       }     


  //     }

  //   });
  // }

  starthttprequest(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.$unSubscribeSubmitData1 = this.uploadService.datauploadstatusandgriddata(data).subscribe(
        (res: any) => {
          console.log(res);
          if (res.HasValidData) {
            let jsonData = res;
            this.Loader.hideLoader();
            if (jsonData.Data.length > 0) {
              this.statusdata = JSON.parse(jsonData.Data);
              this.statusdata.sort((a:any, b:any) => b.sort_id - a.sort_id);
            } else {
              this.statusdata = [];
            }
            
            resolve(res); // Resolve the promise with the response
          } else {
            this.Loader.hideLoader();
            if (!this.isErrorpopupshown) {
              this.errorPopup(res.ExceptionMessage);
              this.isErrorpopupshown = true;
            }
            reject(new Error(res.ExceptionMessage)); // Reject the promise with the error message
          }
        },
        (error) => {
          this.Loader.hideLoader();
          reject(error); // Reject the promise with the error
        }
      );
    });
  }
  errorPopup(message: string, headerTxt: string = 'Error'): void {
    const alertObj: AlertMessageModel = {
      header: headerTxt,
      message: message,
      show: true,
    };
    this._alertService.showAlert(alertObj);
  }

  checkFileName(event: any, section: string){
    let selectedSubmitData = JSON.parse(JSON.stringify(this.UploadObject[0])); 
    let clientName :string = selectedSubmitData.client_name.toLowerCase()
    clientName = clientName.replace(/\s+/g, '-');
    switch(section){
      case "section1":
        return event.target.files[0].name == `RawData.zip`;
      case "section2":
        return event.target.files[0].name == `${clientName.toLowerCase()}_${selectedSubmitData.study.toLowerCase()}_IE_FILE.csv`;
      case "section3" :
        return event.target.files[0].name == `${clientName.toLowerCase()}_${selectedSubmitData.study.toLowerCase()}_Variable_Mapping.xlsx`;
      case "section4" :
        return event.target.files[0].name == `timeperiod.zip`;
      case "section5" :
        return event.target.files[0].name == `${clientName.toLowerCase()}_${selectedSubmitData.study.toLowerCase()}_fom_Inputsfile.xlsx`;
      case "section7" : 
        return event.target.files[0].name == `${clientName.toLowerCase()}_${selectedSubmitData.study.toLowerCase()}_fom_mapping.xlsx`;
      case "section9" :
        return event.target.files[0].name == `Config_UAT.xlsx`;
    }
    
    return false;
  }
  onFileInput(event: any, section: string) {
    if (section != "section1" && section != "section4") {
      if (event.target.files[0] && event.target.files[0].type === 'application/x-zip-compressed') {
        this.errorPopup('ZIP files are not allowed.');
        event.target.value = ''; // Clear the file input
      }
    }

    if(!this.checkFileName(event,section)){
      this.errorPopup('Please follow FileName Nomenclature.');
      event.target.value = '';
      return;
    }

    this.UpdateFileName(event, section)
    this.GetFileList(event.target.files);
    this.defaultPlayButton[section] = true;
    if (this.totalfiles.length > 0) {
      if( section == "section5" ){
        this.fomInputPlayButtonEnable = false;
      }
      else if( section == "section7" ){
        this.fomFilePlayButtonEnable = false;
      }
      else if( section == "section9"){
        this.isfinalLoadTrigger = false;
      }
      else if( section == "section4"){
        this.timePeriodPlayButtonEnable = false;
      }
      else{
        this.isFilenotAvailable = false;
      }
      this.isButtonDisableWhenClickOnTrigger = false;
    }
  }

  GetFileList(files: any) {
    this.totalfiles = [];
    for (let index = 0; index < files.length; index++) {
      this.file = files[index];
      this.totalfiles.push(this.file);
    }
  }
  
  UpdateFileName(event: any, section: string) {
    switch (section) {
      case 'section1':
        this.BindFileName.sanityInput = event.target.files[0].name;
        break;
      case 'section2':
        this.BindFileName.inclusionInput = event.target.files[0].name;
        break;
      case 'section3':
        this.BindFileName.mappingInput = event.target.files[0].name;
        break;
      case 'section4':
        this.BindFileName.timeperiodInput = event.target.files[0].name;
        break;
      case 'section5':
        this.BindFileName.fomInput = event.target.files[0].name;
        break;
      case 'section7':
        this.BindFileName.fomFile = event.target.files[0].name;
        break;
      default:
        this.BindFileName.finalLoadInput = event.target.files[0].name;
        break;
    }
  }
  onTrigger(section: string, folderName: string) {
    this.isErrorpopupshown = false;
    this.isTriggered[section] = true;
    this.defaultPlayButton[section] = false;

    if (this.totalfiles !== undefined && this.totalfiles.length > 0) {
      this.dropdownSelection.JobType = this.getJobNameBasedOnSection(section);
      this.dropdownSelection.FolderName = folderName;
      this.Loader.showLoader();
      this.isButtonDisableWhenClickOnTrigger = true;
      if(section == "section6"){
        this.fomInputPlayButtonEnable = true;
      }else if( section == "section7"){
        this.fomFilePlayButtonEnable = true;
      }else if( section == "section4" ){
        this.timePeriodPlayButtonEnable = true;
      }
      else if( section == "section9" ){
        this.isfinalLoadTrigger = true;
      }
      else{
        this.isFilenotAvailable = true;
      }
      
      this.uploadService.fileUpload(this.dropdownSelection, this.totalfiles, folderName).subscribe(async (result: any) => {
        // Validate the response
        if (result.HasValidData) {
          if (this.UploadObject.length > 0) {
            this.totalfiles = [];
            let selectedsubmitdata = JSON.parse(JSON.stringify(this.UploadObject[0])); 
            this.intervalId = setInterval(async () => {
              try {
                const response = await this.starthttprequest(selectedsubmitdata);
                // Process the response
                if (response.HasValidData) {
                  if (response.Data && response.Data.length > 0) {
                    // this.totalfiles.push(...response.Data); // Example processing, adjust as needed
                    // this.isFilenotAvailable = false;
                    if (this.totalfiles.length == 0) {
                      if( section == "section6" ){
                        this.fomInputPlayButtonEnable = true;
                      } else if( section == "section7" ){
                        this.fomFilePlayButtonEnable = true;
                      }
                      else if( section == "section9"){
                        this.isfinalLoadTrigger = true;
                      }else if( section == "section4" ){
                        this.timePeriodPlayButtonEnable = true;
                      }
                      else{
                        this.isFilenotAvailable = true;
                      }
                      this.isButtonDisableWhenClickOnTrigger = false;
                    }
                  } else {
                    if (this.totalfiles.length == 0) {
                      if( section == "section6" ){
                        this.fomInputPlayButtonEnable = true;
                      } else if( section == "section7" ){
                        this.fomFilePlayButtonEnable = true;
                      }else if( section  == "section9" ){
                        this.isfinalLoadTrigger = true;
                      }else if( section == "section4" ){
                        this.timePeriodPlayButtonEnable = true;
                      }
                      else{
                        this.isFilenotAvailable = true;
                      }
                      this.isButtonDisableWhenClickOnTrigger = false;
                    }
                  }
                }
                // Example stopping condition
                // if (this.totalfiles.length >= 10) { // Adjust this condition as needed
                //     clearInterval(intervalId);
                // }
              } catch (error) {
                console.error('Error in interval:', error);
                clearInterval(this.intervalId); // Stop interval on error
              }
            }, 1000);

          } else {
            this.fomFilePlayButtonEnable = true;
            this.fomInputPlayButtonEnable = true;
            this.isFilenotAvailable = true;
            this.isButtonDisableWhenClickOnTrigger = false;
            this.isfinalLoadTrigger = true;
            this.timePeriodPlayButtonEnable = true;
          }
        }else{
          this.isButtonDisableWhenClickOnTrigger = false;
        }
        // Hide the loader
        // this.Loader.hideLoader();
      }, (error) => {
        this.isTriggered[section] = false;
        this.Loader.hideLoader();
        this.errorPopup("Error : " + error.message);
      });
    } else {
      this.errorPopup("Please upload files");
    }
  }
  getJobNameBasedOnSection(section: string) {
    switch (section) {
      case 'section1':
        if(this.dropdownSelection.fileType.toLowerCase() == "spss")
            return "sanity_spss";
        if( this.dropdownSelection.fileType.toLowerCase() == "gdf")
            return "sanity_gdf";
        if( this.dropdownSelection.fileType.toLowerCase() == "crdom")
            return "sanity_crdom"
        return "sanity_spss";
      case 'section2':
        return "inclusion"
      case 'section3':
        return "variableMapping"
      case 'section4':
        return "timpePeriod"
      case 'section6':
        return "fomInput"
      case 'section7':
        return "fomFile"
      default:
        return "finalLoad"
    }
  }
  OnDownloadFile(section: string, folderName: string) {
    this.Loader.showLoader();
    let multifolder: any;
    if (section.toLowerCase() == "section2") {
      // if (this.statusdata[0].new_variable_file_flag) {
      //   multifolder = this.Folders.inclusion;
      // }
      // else {
        multifolder = this.Folders.inclusion;
        //multifolder = folderName;
      //}

    }
    else if (section.toLowerCase() == "section4") {
      multifolder = this.Folders.timeperiod;
    }
    else {
      multifolder = folderName;
    }
    this.uploadService.downloadFile(this.dropdownSelection, folderName, multifolder).subscribe((result: any) => {
      //validate the response
      this.downloadPPTFile(result, multifolder)

      // hide the loader
      this.Loader.hideLoader();
    });
  }
  private downloadPPTFile(data: any, filename: string) {
    const filesJson = JSON.parse(data.Data);

    Object.keys(filesJson).forEach((fileName) => {
      const base64Content = filesJson[fileName].Base64Content;
      // Decode base64 content
      const binaryContent = atob(base64Content);
      // Convert binary content to ArrayBuffer
      const arrayBuffer = new ArrayBuffer(binaryContent.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryContent.length; i++) {
        uint8Array[i] = binaryContent.charCodeAt(i);
      }
      // Create blob from ArrayBuffer
      const blob = new Blob([arrayBuffer], { type: filesJson[fileName].FileExtension });

      // Create link element for download
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = filesJson[fileName].FileName;

      // Trigger download
      document.body.appendChild(a);
      a.click();
      // Cleanup
      document.body.removeChild(a);
      URL.revokeObjectURL(a.href);
    });
  }
  onClientChange(event: any, selecteddropdown: any) {
    this.UploadObject = [];
    if (selecteddropdown.toLowerCase() === "client") {
      this.isStudySelected = false;
      let clientName = event.target.value;
      const selectedClient: any = this.UserClientStudyData.find((client: { ClientName: string; }) => client.ClientName === clientName);
      if (selectedClient) {
        this.dropdownSelection.client_name = clientName;
        this.selectedClientStudies = selectedClient.UniqueStudies;
        // Reset selected study when client changes
        this.selectedStudy = '';
        this.dropdownSelection.study = "";
        this.dropdownSelection.fileType = "";
        this.isclientSelected = true; 

      } else {
        this.selectedClientStudies = [];
        this.selectedStudy = '';
        this.isclientSelected = false;
        this.dropdownSelection.client_name = "";
        this.dropdownSelection.study = "";
        this.dropdownSelection.fileType = "";
      }
    }
    else if (selecteddropdown.toLowerCase() === "study") {
      if (this.isclientSelected) {
        let studyName = event.target.value;
        const selectedStudy: any = this.selectedClientStudies.find(study => study === studyName);
        if (selectedStudy) {
          this.isStudySelected = true;
          this.selectedStudy = selectedStudy;
          this.dropdownSelection.study = selectedStudy;
        }
        else {
          this.isStudySelected = false;
          this.selectedStudy = '';
          this.dropdownSelection.study = "";
        }
      }
      else {
        this.selectedStudy = '';
        this.isStudySelected = false;
      }
    }
    else {
      let fileName = event.target.value;
      this.dropdownSelection.fileType = fileName;
      //this.Is_Submit = true;
      // this.UploadObject.push(this.dropdownSelection);
    }
    if (this.dropdownSelection.client_name != "" && this.dropdownSelection.fileType != "" && this.dropdownSelection.study != "") {
      //this.UploadObject.push(this.dropdownSelection);
      this.Is_Submit = true;
    }
    else {
      this.Is_Submit = false;
    }
  }
  ngOnDestroy() {
    if (this.$unSubscribeSubmitData1) {
      this.$unSubscribeSubmitData1.unsubscribe();
    }
  }
}


